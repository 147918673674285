.upload-list
  display: flex
  flex-wrap: wrap
  ma: 0 -10px
  +mq-xl
    ma: 0 -16px
  &__
    &item
      h: 58px
      border: 3px solid $c-grey2
      pa: 20px
      display: flex
      align-items: center
      color: $c-black
      fs: 14px
      ma: 0 10px
      +mq-xl
        ma: 0 16px

      &--initial
        ma: 0 !important
        width: 100%

    &file
      &-
        &wrapper
          display: none
    &remove
      fs: 10px
      ml: 22px
      cursor: pointer

      &--initial
        ml: 15px
        fs: 13px

  &--initial
    ma: 0 !important
    position: relative
