@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.eot')
	src: url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype')
	src: url('./../fonts/svgfont.woff') format('woff')
	src: url('./../fonts/svgfont.woff2') format('woff2')
	src: url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-attach: "\EA01"
$icon-close: "\EA02"
$icon-copy: "\EA03"
$icon-dropdown: "\EA04"
$icon-emergency: "\EA05"
$icon-envelope: "\EA06"
$icon-marker: "\EA07"
$icon-next: "\EA08"
$icon-phone: "\EA09"
$icon-prev: "\EA0A"
$icon-fb: "\EA10"
$icon-inst: "\EA11"
$icon-twt: "\EA12"
$icon-invisible: "\EA13"
$icon-isvisible: "\EA14"
$icon-save: "\EA15"
$icon-order: "\EA16"
$icon-date: "\EA17"
$icon-time: "\EA18"
$icon-stats: "\EA19"
$icon-stats-phone: "\EA20"
$icon-message: "\EA21"
$icon-send: "\EA22"
$icon-warning: "\EA23"
$icon-menu-close: "\EA24"
$icon-menu-open: "\EA25"
$icon-info: "\EA26"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	// line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	vertical-align: middle

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon--type_attach:before
	content: $icon-attach
.icon--type_close:before
	content: $icon-close
.icon--type_copy:before
	content: $icon-copy
.icon--type_dropdown:before
	content: $icon-dropdown
.icon--type_emergency:before
	content: $icon-emergency
.icon--type_envelope:before
	content: $icon-envelope
.icon--type_marker:before
	content: $icon-marker
.icon--type_next:before
	content: $icon-next
.icon--type_phone:before
	content: $icon-phone
.icon--type_prev:before
	content: $icon-prev
.icon--type_fb:before
	content: $icon-fb
.icon--type_inst:before
	content: $icon-inst
.icon--type_twt:before
	content: $icon-twt
.icon--type_invisible:before
	content: $icon-invisible
.icon--type_isvisible:before
	content: $icon-isvisible
.icon--type_save:before
	content: $icon-save
.icon--type_order:before
	content: $icon-order
.icon--type_date:before
	content: $icon-date
.icon--type_time:before
	content: $icon-time
.icon--type_stats:before
	content: $icon-stats
.icon--type_stats-phone:before
	content: $icon-stats-phone
.icon--type_message:before
	content: $icon-message
.icon--type_send:before
	content: $icon-send
.icon--type_warning:before
	content: $icon-warning
.icon--type_menu-close:before
	content: $icon-menu-close
.icon--type_menu-open:before
	content: $icon-menu-open
.icon--type_info:before
	content: $icon-info