.link
  color: $c-common
  cursor: pointer
  &:hover
    text-decoration: underline
  &--
    &color
      &_
        &white
          color: $c-white
        &black
          color: $c-black