.control
  w: 100%
  &__
    &elements
      position: relative
      display: flex
    &label
      position: absolute
      top: 18px
      fs: 13px
      left: 20px
      color: $c-grey1
      fw: $fw-medium
      pointer-events: none
      transition: .3s all ease
      &.is-active
        top: 2px
        fs: 11px
        fw: $fw-bold
        transition: .3s all ease
      &--
        &datepicker
          left: 38px
    &button
      position: absolute
      bottom: 0
      right: 0
      left: auto
      w: 168px
    &meta
      position: relative
    &icon
      position: absolute
      top: 50%
      transform: translateY(-50%)
      color: $c-black
      left: auto
      right: 20px
      pointer-events: none
      fs: 5px
      display: flex
      z-index: 2