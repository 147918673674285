.control-descriptor
  position: relative
  display: flex
  justify-content: center
  flex-direction: column

  /* Elements. */
  &__
    &element
      position: relative
      height: 100%
      width: 100%

    &errors
      position: absolute
      top: calc(100% + 10px)
      left: 0
      font-weight: 500
      line-height: 1
      font-size: 12px
      color: red
