.who
  mt: 30px
  +mq-xl
    mt: 40px
  &__
    &wrapper
      +mq-lg
        display: flex
    &content
      +mq-lg
        pr: 100px
        max-w: 770px
        w: 100%
    &info
      mt: 20px
      +mq-lg
        mt: 0
        w: calc(100% - 770px)
    &icons
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      ma: -10px
    &item
      display: flex
      flex-direction: column
      align-items: center
      pa: 10px
      w: 33.33%
    &icon
      max-w: 100%
      max-h: 100%
      &-
        &wrapper
          display: flex
          justify-content: center
          align-items: center
          border-radius: 50%
          overflow: hidden
          w: 60px
          h: 60px
          bg-color: $c-common
    &description
      color: $c-black
      mt: 15px
      fs: 16px
      +mq-md
        fs: 18px
      +mq-xl
        fs: 20px
        mt: 35px