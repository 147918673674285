.bg
  /* Modifications. */
  &--
    /* ********************** */
    /* Normal color. ******** */
    /* ********************** */
    &black
      background: $c-black !important
    &white
      background: $c-white !important
    &grey4
      background: $c-grey4 !important

    /* ********************* */
    /* Hover color. ******** */
    /* ********************* */
    &hover
      &_black
        &:hover
          background: $c-black

.c
  /* Modifications. */
  &--
    /* ********************** */
    /* Normal color. ******** */
    /* ********************** */
    &black
      color: $c-black
    &red
      color: $c-red

    /* ********************* */
    /* Hover color. ******** */
    /* ********************* */
    &hover
      &_common
        &:hover
          color: $c-common
      &_black
        &:hover
          color: $c-black
      &_red
        &:hover
          color: $c-red
