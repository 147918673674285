.order
  bg-color: $c-white
  pa: 10px
  +mq-sm
    pa: 20px
  +mq-md
    pa: 30px
  +mq-xl
    pa: 45px 70px 70px 70px
  &__
    &content
      mt: 30px
      +mq-xl
        mt: 40px
  &--
    &checkout
      mt: -40px
      +mq-xl
        mt: -65px