.menu
  background: initial
  transition: 1s ease
  position: fixed
  top: 90px
  right: 0
  height: 100%
  width: 100%
  z-index: 9

  /* Elements. */
  &__
    &overlay
      background: rgba(0, 0, 0, 0.7)
      transition: 1s ease
      top: 0
      right: 0
      height: 100%
      width: 100%

      /* Modifications. */
      &--inactive
        visibility: hidden
        opacity: 0

      &--isactive
        visibility: visible
        opacity: 1

    &sidebar
      box-shadow: inset 0 13px 3px -13px rgba(0, 0, 0, 0.3)
      background: white
      transition: 1s ease
      position: absolute
      top: 0px
      height: calc( 100% - 90px)
      width: 320px
      overflow: hidden
      overflow-y: auto
      z-index: 1000

      /* Modifications. */
      &--inactive
        right: -100%

      &--isactive
        right: 0

    &template
      padding: 30px

  /* Modifications. */
  &--inactive
    opacity: 0
    visibility: hidden

  &--isactive
    opacity: 1
    visibility: visible
