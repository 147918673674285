@mixin mq-mac
  @media ($mq-query-mac)
    @content
    
@mixin mq-hd
  @media ($mq-query-hd)
    @content

@mixin mq-fhd
  @media ($mq-query-fhd)
    @content