.contacts
  bg-color: $c-white
  pa: 40px 16px 40px 16px
  +mq-sm
    w: 50%
  +mq-xl
    pa: 70px 20px 70px 0
  &__
    &title
      mb: 30px
      +mq-xl
        mb: 56px
    &location
      mb: 20px
      +mq-xl
        mb: 46px
    &phone
      mb: 6px