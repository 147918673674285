.withdraw
  background: $c-white
  padding: 20px
  width: 100%

  +mq-sm()
    width: 470px

  +mq-md()
    padding: 60px

  &--invoice
    padding: 20px !important
    width: 100% !important

    +mq-md()
      padding: 30px !important

  &--created
    padding: 20px !important
    width: 100% !important

    +mq-sm()
      width: 100% !important

    +mq-md()
      padding: 30px !important

    +mq-lg()
      width: 820px !important

