.main
  position: relative
  min-h: 77vh
  &--
    &bg
      bg-position: 0
      bg-repeat: no-repeat
      &_cover
        bg-size: cover
    &centered
      display: flex
      align-items: center
    &hidden
      overflow: hidden
body
  font-family: 'Montserrat', sans-serif
  color: $c-grey1
  ma: 0
  fs: 13px
  fw: $fw-medium
  bg-color: $c-grey2
  word-break: break-word
  &.is-overflow
    overflow: hidden
    h: 100%
button,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
input,
optgroup,
option,
p,
a,
select,
textarea
  margin: 0
  line-height: inherit
  font: inherit
  color: inherit
table
  border-collapse: collapse
a
  text-decoration: none
div::-webkit-scrollbar,
textarea::-webkit-scrollbar
  w: 7px
.router-tab::-webkit-scrollbar-thumb,
.router-tab::-webkit-scrollbar
  border-radius: 0 !important
  h: 7px
*
  -webkit-text-size-adjust: none
img
  max-w: 100%
::-webkit-scrollbar-track
  box-shadow: inset 0 0 5px grey
  border-radius: 10px
::-webkit-scrollbar-thumb
  background: $c-common
  border-radius: 10px
::-webkit-scrollbar
  w: 14px
