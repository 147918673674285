.service
  bg-color: $c-white
  position: relative
  z-index: 1
  h: 100%
  &__
    &wrapper
      h: 100%
      pa: 0 10px 10px 10px
      d: flex
      flex-direction: column
      justify-content: space-between
      +mq-md
        pa: 0 20px 20px 20px
      +mq-xl
        pa: 0 30px 35px 30px
      &--offset_none
        padding: 0
        +mq-md
          padding: 0
        +mq-xl
          padding: 0
    &offset
      pa: 0 10px 10px 10px
      +mq-md
        pa: 0 20px 20px 20px
      +mq-xl
        pa: 0 30px 35px 30px
      &--full
        pa: 10px
        +mq-md
          pa: 20px
        +mq-xl
          padding: 25px 30px
    &title
      fs: 18px
      fw: $fw-bold
      color: $c-yellow-dark
      z-index: 2
      padding-right: 23px
      +mq-md
        fs: 22px
      +mq-xl
        fs: 25px
      &--
        &color
          &_
            &dark
              color: $c-black
        &size_sm
          fs: 18px
          +mq-md
            fs: 18px
          +mq-xl
            fs: 18px
    &content
      mb: 25px
    &image
      min-h: 100%
      min-w: 100%
      border-bottom-left-radius: 15px
      border-bottom-right-radius: 15px
      object-fit: cover
      &-
        &wrapper
          display: flex
          justify-content: center
          h: 205px
    &heading
      display: flex
      // align-items: center
      justify-content: space-between
      mt: 15px
      mb: 15px
      +mq-md
        mt: 25px
      +mq-xl
        mt: 35px
    &counter
      color: $c-black
      fs: 15px
      top: 9px
      position: relative
      min-width: 30px
      text-align: right
      &:after
        content: ''
        position: absolute
        top: 10px
        // transform: translateY(-50%)
        bg-color: $c-grey3
        h: 1px
        w: 15px
        left: -10px
    &footer
      border-top: 1px solid rgba($c-grey1, 0.5)
      &-row
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: space-between
    &link
      &:hover
        text-decoration: underline