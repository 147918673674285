.burger
  width: 30px
  height: 26px
  display: flex
  flex-direction: column
  justify-content: space-between
  cursor: pointer
  z-index: 3
  transition: transform 330ms ease-out
  position: absolute
  top: 50%
  left: 10px
  transform: translateY(-50%)
  +mq-xl
    d: none
  &__
    &item
      background-color: $c-white
      border-radius: 5px
      width: 100%
      height: 4px
      &--
        &half
          width: 50%
        &start
          transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57)
          transform-origin: right
        &end
          align-self: flex-end
          transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57)
          transform-origin: left
  &.is-active
    transform: translateY(-50%) rotate(-45deg)
    .burger
      &__
        &item
          &--
            &start
              transform: rotate(-90deg) translateX(4px)
            &end
              transform: rotate(-90deg) translateX(-4px)