.about
  &__
    &title
      mb: 30px
      +mq-xl
        mb: 44px
    &subtitle
      position: relative
      pa: 12px 0 12px 30px
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        bg-color: $c-common
        h: 100%
        w: 4px
    &wrapper
      +mq-lg
        display: flex
        justify-content: space-between
        align-items: flex-start
    &info
      +mq-xl
        pb: 100px
        pr: 50px
    &form
      bg-color: $c-white
      pa: 20px
      max-w: 442px
      w: 100%
      ma: 20px auto 0 auto
      box-shadow: 0 28px 28px 2px rgba(79, 86, 103, 0.08)
      position: relative
      z-index: 2
      +mq-lg
        pa: 30px
      +mq-xl
        ma: 0 0 -44px 0
        pa: 45px 40px