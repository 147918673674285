.#{$GRID_PREFIX}row
  +cssvar-definition
    --grid-row-space: 30px
  &--
    &justification_
      &flexend
        +mq-both-adaptive
          display: flex
          display: -webkit-box
          justify-content: flex-end
      &between
        +mq-both-adaptive
          justify-content: space-between
      &center
        +mq-both-adaptive
          justify-content: center
      &end
        +mq-both-adaptive
          justify-content: flex-end
    &alignment
      &_
        &end
          align-items: flex-end
        &center
          align-items: center
    &offset
      &_
        &none
          +cssvar-definition
            --grid-row-space: 0
            --grid-space: 0
    &offset
      &_
        &xs
          +cssvar-definition
            --grid-row-space: 20px
            --grid-space: 10px
        &sm
          +cssvar-definition
            --grid-row-space: 20px
            --grid-space: 20px
        &md
          +mq-xl
            +cssvar-definition
              --grid-row-space: 20px
              --grid-space: 30px
    &space_none
      --grid-row-space: 0
