.upload
  &__
    &input
      display: none
    &button
      w: 100%
      +mq-sm
        w: 230px
    &error
      color: $c-red
    &errors,
    &message
      mb: 20px
    &message
      mt: 20px