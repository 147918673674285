.pagination

  --white: #ffffff
  --black: #000000

  &__
    &action
      background: var(--white)
      position: relative
      width: 100%
      padding: 20px
      //display: flex
      //align-items: center

      +mq-md()
        padding: 12px 30px

    &list
      background: var(--white)
      position: relative
      display: flex
      align-items: center
      justify-content: center
      padding: 0 35px !important

    &next
      right: 15px

    &prev
      left: 15px

    &prev,
    &next
      //margin-top: -12px !important
      position: absolute
      font-size: 12px
      height: 18px
      width: 7px

    &prev,
    &next,
    &item
      cursor: pointer
      padding: 0
      margin: 0

      &:before
        background: initial
        color: var(--black)
        height: initial
        width: initial
        top: initial

    &item
      padding: 30px 0
      margin: 0 10px
      font-weight: 700
      font-size: 15px
      line-height: 1.5

      +mq-sm()
        padding: 30px 10px
      +mq-lg()
        padding: 30px 0
      +mq-xl()
        padding: 30px 10px

      &--inactive,
      &--active
        pointer-events: none
        color: var(--black)
      &--active
        box-shadow: inset 0px -4px 0px 0px rgba(253,226,17,1)

.is-disabled
  pointer-events: none
  opacity: 0.5
