.stats
  background: $c-white
  padding: 20px
  height: 100%

  +mq-lg()
    padding: 30px

  &__
    &header
      border-bottom: 1px solid $c-grey3
      padding-bottom: 15px

      +mq-lg()
        padding-bottom: 20px

    &icon
      font-size: 20px
      color: $c-grey4

    &body
      padding-top: 15px

      +mq-lg()
        padding-top: 20px

      &--flexible
        display: flex
        justify-content: space-between

    &content
      &--line
        border-left: 1px solid $c-grey3
      &--left
        width: 75%
      &--right
        width: 25%
        display: flex
        align-items: center
        flex-direction: column

