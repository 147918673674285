$c-white: #fff
$c-black: #000
$c-common: #fde211
$c-yellow-dark: #f8cd35
$c-red: #dc3545
$c-green: #65b148
$c-grey1: #828282
$c-grey2: #f7f8f9
$c-grey3: #dfdfdf
$c-grey4: #727f8f
$c-grey5: #dfe3e8
$c-blue: #0292dc
