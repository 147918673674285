@mixin placeholders
  font-family: 'Montserrat', sans-serif
  color: $c-white
  opacity: 1
  fs: 12px
input,
textarea
  outline: none
  -webkit-appearance: none
  border: 0
  fs: 12px
  color: $c-grey1
  bg-color: $c-grey2
  fw: $fw-medium
  &::placeholder
    +placeholders
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder
    +placeholders

textarea
  resize: none
  overflow: auto
  bg-color: transparent
  w: 100%
  h: 100%
  pa: 0

input[type="date"]::-webkit-calendar-picker-indicator
  w: 100%

input[type="date"],
input[type='number'],
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0
  
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator
  display: none
  -webkit-appearance: none

input[type=number]
  -moz-appearance: textfield