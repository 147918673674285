.el-selector

  --background1: #F7F8F9
  --text1: #000000
  --text2: #888888

  background: var(--background1)
  color: var(--text2)
  position: relative
  padding: 0 20px
  height: 58px
  width: 100%
  min-width: 115px

  display: flex
  align-items: center

  /* Elements. */
  &__
    &placeholder
      box-shadow: inset -50px 0px 25px 0px rgba(247,248,249,1)
      cursor: pointer
      width: 100%
      display: flex
      align-items: center
      justify-content: space-between
      font-weight: 600

    &title
      max-width: calc(100% - 30px)
      overflow: hidden
      // white-space: nowrap
      line-height: 1.2
      font-weight: 600

    &icon
      font-size: 7px
      color: var(--text2)

    &options
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3)
      background: $c-white
      padding: 3px
      position: absolute
      width: calc(100% - 2px)
      top: 100%
      left: 1px
      z-index: 9
      max-height: 177px
      overflow: hidden
      overflow-y: auto

    &list
      padding: 5px 0 !important
      overflow-y: auto

    &item
      transition: .5s ease
      padding: 5px 16px
      cursor: pointer
      margin: 0
      font-weight: 600 !important

      &:before
        background: initial
        content: ''

      &:hover
        background: #727f8f
        .text
          color: #F8DD11

    &errors
      position: absolute
      top: calc(100% + 10px)
      left: 0
      font-weight: 500
      line-height: 1
      font-size: 12px
      color: red

  /* Modifications. */
  &--inactive
    opacity: .5
    pointer-events: none
