.statistic
  &__
    &wrapper
      display: flex
    &item
      pr: 45px
      pt: 11px
      pb: 11px
      & + &
        border-left: 1px solid $c-grey3
      &:last-child
        pr: 0
        pl: 45px
    &text,
    &count
      color: $c-black
    &text
      fs: 20px
      fw: $fw-medium
    &count
      fs: 28px
      fw: $fw-bold