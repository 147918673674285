.#{$GRID_ROW_PREFIX}container
  w: 100%
  +cssvar-definition
    --grid-space: 30px

  /* Modifications. */
  &--variant
    &_common
      @extend .ug-container
      @extend .ug-container--space_md
      @extend .ug-container--fit_sm-sm
      @extend .ug-container--fit_md-md
      @extend .ug-container--fit_lg-lg
      @extend .ug-container--fit_xl-xl
    &_large
      @extend .ug-container
      @extend .ug-container--space_md
      @extend .ug-container--fit_sm-sm
      @extend .ug-container--fit_md-md
      @extend .ug-container--fit_lg-lg
      @extend .ug-container--fit_xl-xl
      @extend .ug-container--fit_fhd-mac
