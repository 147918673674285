.review
  mt: 20px
  +mq-xl
    mt: 60px
  &__
    &author
      mb: 4px
    &detail
      mt: 15px
    &rating
      mr: 30px
    &item
      position: relative
      &:after
        +mq-sm
          content: ''
          position: absolute
          right: -20px
          top: 0
          h: 100%
          w: 1px
          bg-color: $c-grey3
        +mq-lg
          right: -57px
      &:last-child
        &:after
          d: none
    &text
      white-space: pre-wrap