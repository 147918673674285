.modal
  bg-color: $c-white
  position: relative
  &__
    &content
      pa: 30px 10px
      +mq-md
        pa: 30px 20px
      +mq-xl
        pa: 40px 50px
    &success
      w: 55px
      h: 51px
      ma: 0 auto 25px auto
    &button
      w: 166px
      &--
        &info
          ma: 33px auto 0 auto
    &info
      &-
        &title
          mb: 20px
    &form
      ma: 24px 0 38px 0
  &--
    &decorate
      position: relative
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        bg-color: $c-common
        h: 100%
        w: 10px