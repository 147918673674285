.section
  position: relative
  pa: 40px 0
  +mq-xl
    pa: 65px 0
  &--
    &offset
      &lg
        +mq-xl
          pa: 54px 0
      &xs
        pa: 20px 0
        +mq-xl
          pa: 20px 0
  &--
    &login
      width: 100%
      display: flex
      align-items: center
      flex-direction: column
    &main
      pa: 65px 0
      +mq-lg
        pa: 80px 0 65px 0
      +mq-xl
        pa: 105px 0 0 0
    &bg
      bg-size: cover
      bg-position: 50%
      bg-repeat: no-repeat
      &_
        &white
          bg-color: $c-white
    &contacts
      +mq-xl
        pa: 174px 0
    &blog
      pa: 35px 0
      +mq-xl
        pa: 95px 0
    &checkout
      pa: 60px 0 80px 0
      +mq-xl
        pa: 130px 0 205px 0
    &template
      pa: 80px 0 80px 0
      +mq-xl
        pa: 150px 0 180px 0
    &pt
      &_
        &none
          pt: 0
          +mq-xl
            pt: 0
    &pb
      &_
        &none
          pb: 0
          +mq-xl
            pb: 0

.img
  &--login-circle
    transform: translate(-50%, -50%)
    left: calc( 50% - 140px )
    top: calc( 50% - 0px )
    position: absolute
    height: 1039px
    width: 1139px
    z-index: -1
