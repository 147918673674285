.textarea
  position: relative

  +mq-md()
    height: 100%

  .control-descriptor,
  .control-input
    +mq-md()
      height: 100%

  .control-input

    /* Elements. */
    &__
      &element
        min-height: 140px
        height: 100%
        resize: none
        +mq-md()
          padding-bottom: 60px
