.copy
  display: flex
  position: relative
  w: 100%
  max-w: 515px
  &__
    &field
      w: calc(100% - 70px)
      max-w: 445px
      bg-color: $c-grey2
      box-sizing: border-box
      h: 100%
      pa: 12px
      +mq-sm
        pa: 20px 24px
    &content
      fs: 14px
      color: $c-black
      fw: $fw-bold
      word-break: break-all
    &button
      display: flex
      justify-content: center
      align-items: center
      position: absolute
      top: 0
      right: 0
      bg-color: $c-common
      fs: 25px
      w: 70px
      h: 100%
      color: $c-black
      cursor: pointer
      transition: .3s all ease
      &:hover
        bg-color: $c-grey4
        color: $c-common
        transition: .3s all ease
      &.is-success
        bg-color: $c-green
        color: $c-white
      &.is-error
        bg-color: $c-red
        color: $c-white