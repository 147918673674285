.control-input
  h: 58px
  w: 100%
  fs: 12px
  pa: 18px 20px
  lh: 10px
  border-radius: 0
  box-sizing: border-box
  box-shadow: inset -1px 1px 4px rgba(0, 0, 0, 0.08)
  color: $c-grey1
  bg-color: $c-grey2
  border: 0
  &--
    &textarea
      lh: 14px
      pb: 10px