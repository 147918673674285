.blog-item
  bg-color: $c-white
  &__
    &offset
      pa: 0 10px 10px 10px
      +mq-md
        pa: 0 20px 20px 20px
      +mq-xl
        pa: 0 30px 35px 30px
      &--full
        pa: 10px
        +mq-md
          pa: 20px
        +mq-xl
          padding: 25px 30px
    &header
        border-bottom: 1px solid rgba($c-grey1, 0.5)
        &-row
          display: flex
          align-items: center
          flex-wrap: wrap
          justify-content: space-between
    &title
      margin-bottom: 30px

.social-row
  display: flex
  flex-wrap: wrap
  margin: 0 -10px
  &__item
    margin: 0 5px


.share-link
  display: inline-flex
  cursor: pointer
  text-decoration: none
  border-radius: 50%
  background-color: $c-yellow-dark
  color: $c-black
  width: 30px
  height: 30px
  border: 2px solid transparent
  i
    margin: auto
  &:hover
    border-color: $c-green
  &--black
    color: $c-yellow-dark
    background-color: $c-black
      
.btn-route-link
  text-decoration: none


.vue-notification
  padding: 10px
  margin: 10px !important
  color: $c-black !important
  background-color: $c-white !important
  border-left-color: $c-yellow-dark!important