.#{$GRID_COLS_PREFIX}cols
  width: 100%
  &--
    &justification_
      &flexend
        +mq-both-adaptive
          display: flex
          display: -webkit-box
          justify-content: flex-end
      &center
        +mq-both-adaptive
          display: flex
          align-items: center
    &offset
      &_none
        ma: 0
    &init
      width: auto
    &6
      w: 50%
      &-
        &xs
          w: 50%
    &4
      &-
        &hd
          +mq-hd
            w: 33.3332333333%
