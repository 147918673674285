table
  w: 100%
  border-spacing: 0
  border: 0
  border-collapse: collapse
  caption
    mb: 20px
  tr
    bg-color: $c-grey2
    &:nth-child(odd)
      bg-color: rgba($c-grey5, .38)
  th
    fw: 700
    fs: 16px
    bg-color: $c-common
  thead
    bg-color: rgba($c-grey5, .67)
    color: $c-black
    tr
      bg-color: rgba($c-grey5, .67)
      fs: 16px
      fw: 700
  tbody
    bg-color: $c-white
  tr,
  td,
  th
    border: 0
  td,
  th
    pa: 15px
    +mq-md
      pa: 20px


.table-invoice
  padding-bottom: 15px
  width: 100%
  overflow: hidden
  overflow-x: auto

  &__
    &info-wrapper
      display: flex
      align-items: center
      text-align: left

      &--pointer
        cursor: pointer

    &icon
      font-weight: 700
      font-size: 9px
      color: $c-black

    &icon-copy
      font-size: 13px
      margin-left: 8px
      color: $c-black
      cursor: pointer

    &text
      line-height: 1.5

    &icon
      position: relative
      margin-left: 7px
      cursor: pointer

      &--tool
        font-size: 13px
        &:hover
          .table-invoice__info
            display: block

    &info
      border: 2px solid #eaecf0
      background: $c-white
      position: absolute
      padding: 10px
      width: 200px
      font-size: 13px
      right: 0
      line-height: 1.4
      font-weight: 500
      display: none

    &th,
    &td
      border-bottom: 2px solid #eaecf0
      min-width: 150px !important
      padding: 12px !important

      &:first-child
        border-left: 2px solid #eaecf0

      &:last-child
        border-right: 2px solid #eaecf0

      &--link
        min-width: 75px !important
        max-width: 75px !important
        width: 65px !important

      &--id
        min-width: 110px !important
        max-width: 110px !important
        width: 92px !important

    &th
      background-color: #eaecf0
    &td
      background-color: #ffffff
