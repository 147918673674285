/** https://www.npmjs.com/package/vue-js-modal */
.v--modal-background-click
  height: 100% !important

.v--modal-overlay
  background-color: rgba(0, 0, 0, 0.75) !important

.toasted-primary
  background-color: $c-grey4 !important
  font-weight: 500 !important
  color: $c-white !important

.vue-map-container
  position: initial

.v--modal-box
  top: 50% !important
  transform: translate(0, -50%) !important

.cabinet-modal
  --close: #000000
  --timer: #fde211

  box-shadow: initial !important
  overflow: initial !important
  background: $c-white
  height: auto !important

  /* Elements. */
  &__
    &close
      color: var(--close)
      position: absolute
      height: 26px
      width: 26px
      right: 5px
      top: 5px
      font-size: 15px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      z-index: 1

    &timer
      background: var(--timer)
      color: var(--close)
      border-radius: 100%
      margin: 0 auto
      height: 80px
      width: 80px
      display: flex
      justify-content: center
      align-items: center

  /* Modifications. */
  &--
    &container
      max-width: 90% !important
      max-height: 85% !important
      left: 0 !important
      margin: 15px auto !important
      overflow: hidden !important
      overflow-y: auto !important

    &cookie
      background: var(--theme-salva)
      position: fixed
      display: flex
      align-items: center
      max-height: 300px
      // height: 100%
      width: 100%
      bottom: 0
      z-index: 999

    &miss-off
      overflow: initial !important
      overflow-y: initial !important

    &size_
      &sm, &md, &lg, &xl
        max-width: 90% !important

      &sm
        +mq-md()
          width: 450px !important

      &md
        +mq-md()
          width: 700px !important

      &lg
        +mq-md()
          width: 992px !important

      &xl
        +mq-md()
          width: 1170px !important

      &full
        .cabinet-modal__close
          box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.8) !important
          background: $c-grey4 !important
          color: $c-common !important

        max-height: calc(100% - 15px) !important
        height: calc(100% - 15px) !important
        max-width: calc(100% - 15px) !important
        width: calc(100% - 15px) !important
        margin: 0 auto !important

        +mq-md()
          max-height: calc(100% - 40px) !important
          height: calc(100% - 40px) !important
          max-width: calc(100% - 40px) !important
          width: calc(100% - 40px) !important

        +mq-lg()
          max-height: calc(100% - 90px) !important
          height: calc(100% - 90px) !important
          max-width: calc(100% - 90px) !important
          width: calc(100% - 90px) !important
