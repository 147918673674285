.footer
  bg-color: $c-white
  pa: 30px 0
  &__
    &wrapper
      flex-wrap: wrap
      display: flex
      align-items: center
      flex-direction: column
      ta: center
      +mq-xl
        ta: left
        flex-direction: row
        justify-content: space-between
    &copyright
      fs: 12px
      color: $c-black
    &link
      fw: $fw-bold
      color: $c-black
      &:hover
        +mq-xl
          text-decoration: underline
    &item
      ma: 5px 0
      +mq-xl
        ma: 0
    &payment
      ma: 5px 0
      +mq-xl
        ma: 0
      &-
        &image
          display: flex