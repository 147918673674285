.text
  color: $c-grey1
  fw: $fw-medium
  lh: 30px
  fs: 13px
  &--
    &fs
      &_xs
        +mq-adaptive()
          fs: 12px !important
      &_1xs
        +mq-adaptive()
          fs: 13px
      &_md
        +mq-adaptive()
          fs: 14px
      &_1md
        +mq-adaptive()
          fs: 15px
      &_xl
        +mq-adaptive()
          fs: 18px
      &_4xl
        +mq-adaptive()
          fs: 27px
      &_5xl
        +mq-adaptive()
          fs: 30px
      &_6xl
        +mq-adaptive()
          fs: 36px
    &lh
      &_initial
        lh: 1
      &_small
        lh: 1.6
      &_large
        lh: 2.0
      &_xs
        lh: 25px
      &_xxs
        lh: 20px
      &_xl
        lh: 36px
    &color
      &_black
        color: $c-black
      &_red
        color: $c-red
      &_gray1
        color: $c-grey1
      &_gray2
        color: $c-grey2
      &_gray
        color: $c-grey3
      &_blue
        color: $c-blue
    &fw
      &_medium
        fw: $fw-medium
      &_bold
        fw: $fw-bold
    &c
      &_black
        color: $c-black
    &cfg
      &_warning
        padding-left: 20px
        position: relative
        &::before
          font-family: "svgfont"
          content: '\EA23'
          position: absolute
          top: 1px
          left: 0
      &_underline
        text-decoration: underline
      &_pointer
        cursor: pointer
      &_center
        text-align: center
      &_datetime
        +mq-md()
          margin-bottom: 38px
    &action
      &:hover
        text-decoration: underline
