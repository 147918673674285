.personal-circle
  text-decoration: none
  cursor: pointer
  display: flex
  align-items: center

  &__
    &circle
      min-height: 40px
      max-height: 40px
      min-width: 40px
      max-width: 40px
      background: $c-common
      display: flex
      align-items: center
      justify-content: center
      border-radius: 100%
      font-size: 12px
      font-weight: 500
      color: $c-black

    &title
      text-decoration: none
      margin-right: 20px
      font-size: 13px
      color: $c-black
      display: none

      +mq-sm()
        display: block
