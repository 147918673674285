.close
  display: flex
  align-items: center
  justify-content: center
  color: $c-black
  position: absolute
  top: 0
  right: 0
  cursor: pointer
  fs: 10px
  transition: .3s all ease
  w: 34px
  h: 34px
  &:hover
    transition: .3s all ease
    color: $c-common
    bg-color: $c-grey4