.button
  border: 0
  -webkit-appearance: none
  outline: none
  fw: $fw-bold
  fs: 13px
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center
  transition: .3s all ease
  box-sizing: border-box
  color: $c-black
  text-align: center
  bg-color: $c-common
  h: 48px
  w: 100%
  pa: 10px
  &:hover
    bg-color: $c-grey4
    color: $c-common
    transition: .3s all ease

  &:disabled
    bg-color: $c-grey4
  &__
    &icon
      fs: 22px
      mr: 16px
    &load
      width: 30px

  &--
    &bg
      &_transparent
        bg-color: transparent
    &border
      &_common
        border: 3px solid $c-common
    &height
      &_xl
        h: 58px
    &go-back
      font-size: 15px
      height: 38px
      width: 38px
    &copy
      height: 36px
      width: 36px
    &save,
    &go-back
      bg-color: $c-grey4
      color: $c-white
      &:hover
        bg-color: $c-common
        color: $c-black
        transition: .3s all ease
