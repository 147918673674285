.router-tab
  display: flex
  align-items: center
  justify-content: space-between
  overflow: hidden
  overflow-x: auto

  /* Elements. */
  &__
    &common-wrapper
      display: flex
      align-items: center
      width: 100%

      /* Utils. */
      > *:first-child
        padding-left: 0
        margin-left: 0
      > *:last-child
        padding-right: 0

    &action-wrapper
      /* Utils. */
      > *:first-child
        margin-right: 0

    &element
      transition: .5s ease
      padding: 22px 0
      margin: 0 14px
      color: $c-white
      min-width: max-content

      +mq-lg()
        margin: 0 25px

      &--isactive
        color: rgba(253,226,17,1)

        +mq-xl()
          box-shadow: inset 0px -2px 0px 0px rgba(253,226,17,1)

      &--pointer
        cursor: pointer

      &:hover
        color: rgba(253,226,17,1)

        +mq-xl()
          box-shadow: inset 0px -2px 0px 0px rgba(253,226,17,1)

    &text
      font-width: 500
