.multiselect
  w: 100%
  min-h: 58px
  &__
    &single
      bg-color: transparent
      fs: 12px
      pl: 0
      mb: 6px
      pt: 5px
      color: $c-grey1
      fw: $fw-medium
    &select
      h: 58px
      &:before
        border-color: $c-common transparent transparent
    &tags
      min-h: 58px
      bg-color: $c-grey2
      border: 0
      box-shadow: inset -1px 1px 4px rgba(0, 0, 0, 0.08)
      pr: 48px
      pl: 20px
      pt: 14px
      position: relative
      &:after
        content: ''
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 48px
        bg-color: $c-grey3
        w: 1px
        h: 33px
    &element
      pa: 0
      ma: 0
      &:before
        display: none
    &option
      white-space: normal
      &--
        &highlight,
        &selected
          bg-color: $c-common
        &selected
          &.multiselect
            &__
              &option
                &--
                  &highlight
                    bg-color: $c-common
    &content
      &-
        &wrapper
          max-h: 300px !important
  &--
    &relative
      .multiselect
        &__
          &content
            &-
              &wrapper
                position: relative
      .control
        &__
          &icon
            top: 26px
            transform: none
