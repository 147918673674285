.input-wrapper
  display: flex
  justify-content: space-between
  align-items: center
  &__
    &field
      w: calc(100% - 40px)
    &icon
      cursor: pointer
      display: flex
      justify-content: center
      align-items: center
      color: $c-black
      fs: 30px