@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-Black.ttf') format("truetype")
  font-weight: $fw-extra
  font-style: normal

@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-ExtraBold.ttf') format("truetype")
  font-weight: $fw-extra-bold
  font-style: normal

@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-Bold.ttf') format("truetype")
  font-weight: $fw-bold
  font-style: normal

@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-SemiBold.ttf') format("truetype")
  font-weight: $fw-semi-bold
  font-style: normal

@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-Medium.ttf') format("truetype")
  font-weight: $fw-medium
  font-style: normal

@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-Regular.ttf') format("truetype")
  font-weight: $fw-regular
  font-style: normal

@font-face
  font-family: 'Montserrat'
  font-display: swap
  src: url('../../static/fonts/Montserrat-Light.ttf') format("truetype")
  font-weight: $fw-light
  font-style: normal
