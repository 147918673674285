.breadcrumb-wrapper
  display: inline-block
  
.breadcrumb
  list-style-type: none
  display: flex
  align-items: center
  flex-wrap: wrap
  margin: 0 .15rem
  &__
    &item
      font-size: 12px
      margin: 0 .15rem
      font-weight: 500
      padding: 0
      &:before
        display: none
      a
        text-decoration: underline
        color: $c-grey1
        &:hover
          opacity: .8
        &.is-
          &passive
            pointer-events: none
            text-decoration: none
            color: $c-black
    &symbol
      font-size: 10px
      color: $c-grey1
