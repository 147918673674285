.paginate
  background-color: $c-white
  margin-top: 25px
  +mq-xl
    margin-top: 58px

.paginate_btn
  display: flex
  background-color: $c-white
  color: $c-grey1
  fw: $fw-bold
  font-size: 15px
  border-bottom: 4px solid transparent
  padding: 2px
  min-width: 45px
  height: 65px
  justify-content: center
  align-items: center
  transition: .5s all ease
  &--w-full
    width: 100%
  &:hover
    bg-color: $c-grey2
  &__arrow
    color: $c-black
    &--next
      transform: scale(-1)
  &.is-active
    color: $c-black
    border-color: $c-common

.paginate_row
  display: flex
  align-items: center
  &__nav
    width: 55px
  &__list
    flex-grow: 1