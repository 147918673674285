.pa

  /* Modifications. */
  &--
    /* ************** */
    /* Full. ******** */
    /* ************** */
    &f
      &_sm
        +mq-adaptive()
          padding: 15px
      &_1sm
        +mq-adaptive()
          padding: 20px
      &_md
        +mq-adaptive()
          padding: 30px
      &_lg
        +mq-adaptive()
          padding: 40px

    /* ************** */
    /* Top. ********* */
    /* ************** */
    &t
      &_1sm
        +mq-adaptive()
          padding-top: 20px
      &_md
        +mq-adaptive()
          padding-top: 30px

    /* ************** */
    /* Right. ******* */
    /* ************** */
    &r
      &_1sm
        +mq-adaptive()
          padding-right: 20px
      &_md
        +mq-adaptive()
          padding-right: 30px
      &_1md
        +mq-adaptive()
          padding-right: 45px

    /* ************** */
    /* Bottom. ****** */
    /* ************** */
    &b
      &_xs
        +mq-adaptive()
          padding-bottom: 10px
      &_sm
        +mq-adaptive()
          padding-bottom: 15px
      &_1sm
        +mq-adaptive()
          padding-bottom: 20px
      &_md
        +mq-adaptive()
          padding-bottom: 30px

    /* ************** */
    /* Left. ******** */
    /* ************** */
    &l
      &_1sm
        +mq-adaptive()
          padding-left: 20px
      &_md
        +mq-adaptive()
          padding-left: 30px

.ma

  /* Modifications. */
  &--
    &off
      margin: 0 !important
    /* ************** */
    /* Top. ********* */
    /* ************** */
    &t
      &_md
        +mq-adaptive()
          margin-top: 30px
      &_1md
        +mq-adaptive()
          margin-top: 40px

    /* ************** */
    /* Right. ******* */
    /* ************** */
    &r
      &_xxs
        margin-right: 5px
      &_xs
        margin-right: 8px

    /* ************** */
    /* Bottom. ****** */
    /* ************** */
    &b
      &_xxs
        +mq-adaptive()
          margin-bottom: 10px
      &_xs
        +mq-adaptive()
          margin-bottom: 15px
      &_sm
        +mq-adaptive()
          margin-bottom: 20px
      &_md
        +mq-adaptive()
          margin-bottom: 30px
      &_1md
        +mq-adaptive()
          margin-bottom: 40px

    /* ************** */
    /* Left. ******** */
    /* ************** */
    //&l
