.phone
  display: flex
  align-items: center
  &__
    &icon
      color: $c-common
      mr: 20px
      display: flex
      justify-content: center
      align-items: center
      fs: 34px