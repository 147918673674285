.list
  &--
    &fs
      &_
        &sm
          fs: 14px
ol,
ul
  list-style-type: none
  pa: 0
  ma: 10px 0
  li
    position: relative
    pl: 20px
    +mq-lg
      pl: 30px
    &:before
      content: ''
      top: 7px
      left: 0      
      w: auto 
      h: auto 
      color: $c-common
      bg-color: transparent
      transform: translateY(0)
      position: absolute
  li+li
    mt: 10px
    +mq-xl
      mt: 30px
ol
  counter-reset: li
  li
    &:before
      fs: 14px
      fw: 700
      top: 0
      counter-increment: li
      content: counters(li,". ") ". "
ul
  li
    &:before
      content: ''
      bg-color: $c-common
      w: 10px
      h: 10px
      top: 10px