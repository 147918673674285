.control-checkbox
  +control-checker
  +control-checker-default-states
  w: 28px
  h: 28px

  &__
    &label
      w: 28px
      h: 28px
      &:before
        background-color: $c-grey2
        box-shadow: inset -1px 1px 4px rgba(0, 0, 0, 0.08)
        w: 28px
        h: 28px

      &:after
        display: none
        width: 50%
        height: 30%
        transform: translateY(-70%) translateX(-50%) rotate(-45deg)
        transform-origin: 50% 50%
        border: 3px solid $c-black
        border-top-width: 0
        border-right-width: 0
        transition: opacity .1s ease-in-out

  +control-checker-state(checked, $block: '.control-checkbox')
    &:after
      display: block
    &::before
      bg-color: $c-common

  +control-checker-state(indeterminate, $block: '.control-checkbox')
    &:after
      display: block
      background-color: transparent