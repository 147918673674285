.v
  &--
    &modal
      pa: 0 !important
      left: 0 !important
      ma: 0 auto !important
      max-height: 90vh !important
      height: auto !important
      overflow-y: auto !important
      box-sizing: border-box !important
      box-shadow: none !important
      border-radius: 0 !important
      background-color: transparent
      w: 290px !important
      +mq-sm
        w: 430px !important
      &_
        &lg
          .v
            &--
              &modal
                w: calc(100% - 20px) !important
                +mq-sm
                  w: calc(100% - 20px) !important
                +mq-md
                  w: 700px !important
                +mq-lg
                  w: 900px !important
                +mq-xl
                  w: 972px !important
    &modal-background-click
      h: 100%