.table-wrapper
  overflow: auto
  mb: 20px
  &::-webkit-scrollbar
    h: 7px
  &::-webkit-scrollbar-track
    box-shadow: inset 0 0 5px grey
    border-radius: 10px
  &::-webkit-scrollbar-thumb
    background: $c-common
    border-radius: 10px