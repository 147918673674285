.reviews
  &__
    &content
      pa: 20px 0
      w: 100%
      +mq-xl
        pt: 80px
        pb: 50px
    &title
      border-bottom: 1px solid $c-grey3
      pb: 20px
      +mq-xl
        pb: 40px
    &button
      max-w: 310px
      w: 100%
      ma: 0 auto
      pb: 20px
      +mq-xl
        pb: 50px