.arrow-wrapper
  display: flex
  &__
    &item
      bg-color: $c-grey2
      transition: .5s all ease
      w: 38px
      h: 38px
      display: flex
      justify-content: center
      align-items: center
      outline: none
      cursor: pointer
      color: $c-black
      &:hover
        bg-color: $c-common
      &--
        &mini
          width: 23px
          height: 23px
        &bg-common
          bg-color: $c-common
          &:hover
            bg-color: $c-grey2
    &arrow
      transform: scale(-1)
      font-size: 9px

