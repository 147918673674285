input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Fix foxit bug.*/
input[type=number]
  -moz-appearance: textfield

input[type="time"]::-webkit-calendar-picker-indicator
    background: none

.cabinet-textarea
  position: relative
  textarea
    padding: 20px !important
    height: 100px !important
  &__action
    position: absolute
    bottom: 0
    right: 0

.postal-wrapper
  position: relative
  &__action
    transform: translate(0, -50%)
    position: absolute
    cursor: pointer
    right: 15px
    top: 50%
    &--label
      top: calc(50% + 13px)

.input
  .control-descriptor,
  .control-input
    position: relative
    height: 100%

  .control-input
    /* Elements. */
    &__
      &element
        height: 100%
  &--
    &modal
      .control-descriptor__errors
        position: relative
        margin-top: 6px
