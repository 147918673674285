.preloader
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  bg-color: rgba(255,255,255,.5)
  z-index: 2
  &__
    &item
      width: 70px
      height: 70px
      position: absolute
      z-index: 3
      top: 50%
      left: 50%
      border: 8px solid transparent
      border-radius: 50%
      transform: translate(-50%, -50%)
      animation: spinner 0.7s linear infinite
      animation-delay: 0.24s
      &:before,
      &:after
        content: ''
        display: block
        position: absolute
        border: 8px solid transparent
        border-radius: 50%
        box-sizing: border-box
        animation: spinner 0.7s linear infinite
      &:before
        top: 12px
        left: 12px
        right: 12px
        bottom: 12px
      &:after
        top: 2px
        left: 2px
        right: 2px
        bottom: 2px
        animation-delay: 0.12s

@-webkit-keyframes spinner
  from
    border-color: $c-common
  to
    border-color: rgba(190, 101, 226, 0)

@keyframes spinner
  from
    border-color: $c-common
  to
    border-color: rgba(190, 101, 226, 0)
