.template
  lh: 26px
  fs: 13px
  overflow: hidden
  color: $c-grey1
  img
    max-w: 100%
    h: auto
  p + p
    mt: 20px
    +mq-lg
      mt: 30px
  b
    color: inherit
    fw: $fw-bold
  h2,
  h3,
  h4,
  h5,
  h6
    ma: 30px 0
    line-height: 1.3
    color: inherit
    &:first-child
      mt: 0
  a
    color: $c-black
    text-decoration: underline
  blockquote
    bg-color: transparent
    pa: 10px 37px
    ma: 20px 0
    position: relative
    fw: $fw-bold
    color: $c-black
    fs: 14px
    +mq-xl
      ma: 30px 0
    &:after
      content: ''
      position: absolute
      top: 0
      left: 0
      w: 3px
      h: 100%
      bg-color: $c-common
  h2
    fw: $fw-bold
    color: $c-black
    fs: 20px
    +mq-xl
      fs: 30px
  table
    ma: 20px 0
    +mq-lg
      ma: 30px 0
  iframe 
    w: 100%
    h: 250px
    +mq-sm
      h: 300px
    +mq-md
      h: 420px
  &--
    &offset
      &_xs
        p + p
          mt: 10px
          +mq-lg
            mt: 10px