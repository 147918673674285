.caption
  fw: $fw-bold
  color: $c-black
  lh: 1.3
  &--
    &size
      &_
        &1
          fs: 35px
          +mq-md
            fs: 50px
          +mq-xl
            fs: 68px
        &2
          fs: 24px
          +mq-md
            fs: 30px
          +mq-xl
            fs: 35px
        &3
          fs: 20px
          +mq-md
            fs: 24px
          +mq-xl
            fs: 29px
        &4
          fs: 30px
          +mq-md
            fs: 40px
          +mq-xl
            fs: 50px
        &5
          fs: 18px
          +mq-md
            fs: 20px
          +mq-xl
            fs: 20px
    &color
      &_
        &common
          color: $c-common
        &white
          color: $c-white
        &black
          color: $c-black
        &red
          color: $c-red
    &fw
      &_
        &bold
          fw: $fw-bold
        &regular
          fw: $fw-regular
    &ta
      &_
        &center
          ta: center
    &action
      &:hover
        text-decoration: underline