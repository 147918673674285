.control-cabinet
  --background1: #F7F8F9
  --text1: #000000
  --text2: #888888

  position: relative
  display: flex
  flex-direction: column

  $is: #{&}
  &.is-valid
    #{$is}
      &__element
        &:not([invalid])
          color: var(--text2)

  /* Elements. */
  &__
    &element
      background: var(--background1)
      color: var(--text2)
      font-weight: 600
      font-size: 13px
      outline: none
      border: none
      height: 58px
      width: 100%

      padding: 0 20px

      &::placeholder
        color: var(--text2)
        opacity: 1

      &:disabled
        opacity: .7

      -webkit-appearance: none

      &:focus
        -webkit-appearance: none

      &--
        &centered
          padding: 0 15px
          display: flex
          align-items: center

        &border
          background: initial !important
          border: 3px solid $c-grey2
          padding: 10px 20px
          min-height: 58px

        &date,
        &time
          &:before
            font-family: "svgfont"
            position: absolute
            right: 15px
            font-size: 20px
            color: $c-black

        &date
          &:before
            content: '\EA17'

        &time
          &:before
            content: '\EA18'

    &label
      margin-bottom: 10px
      font-weight: 700
      line-height: 1
      font-size: 13px
      color: var(--text1)

    &loopa
      position: absolute
      right: 10px
      top: 50%
      font-size: 16px
      color: var(--text2)
      cursor: pointer

    &flat-toggle
      position: absolute
      right: 15px
      font-size: 16px
      color: $c-black
    
    &copy-action
      transform: translate(0, -50%)
      position: absolute
      right: 10px
      top: 50%

  &--
    &centered
      display: flex
      justify-content: center
