.location
  display: flex
  align-items: center
  &__
    &icon
      color: $c-common
      fs: 32px
      mr: 20px
    &text
      w: calc(100% - 44px)
      max-w: 200px