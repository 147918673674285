.header
  bg-color: $c-white
  pa: 25px 0
  d: block

  &__
    &logo
      box-sizing: content-box
      position: relative
      width: 120px

      img
        height: 100%
        width: 100%

      +mq-sm()
        width: 174px

      &:after
        bg-color: $c-common
        position: absolute
        top: -25px
        left: 0
        height: 6px
        width: 100%
        content: ''

    &burger
      background: $c-common
      border-radius: 100%
      min-height: 40px
      max-height: 40px
      min-width: 40px
      max-width: 40px
      display: flex
      align-items: center
      justify-content: center
      font-size: 13px
      color: $c-black

    &item
      display: flex
      align-items: center
